/* ImagenAgua.css */
.image-container {
  position: relative;
  width: 65%;
  text-align: center;
  
}


.text-overlay {
  margin-top: 3%; /* Ajusta este valor según sea necesario para centrar el texto verticalmente */
}

.text-overlay h2 {
  font-size: 3rem;
  margin: 1rem 0;
}


.buttons {
  margin-top: 1rem;
}
