/* src/components/FacebookButton.css */
.instagram-button {


    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    width: 2cm; /* Especifica el ancho del enlace en cm */
    background-color: #302d2f;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
}

  /*Tuve que hacer una grid por que con container no me daba,
   con grid se agregan 2 columnas para los 2 botones*/
    .grid-container{
      display: grid;
      grid-template-columns: repeat(2, auto);
      justify-content: center; /* Centra los botones horizontalmente */
      gap: 5px; /* Reduce el espacio entre los botones */
                 }

  .icons8-instagram-94{
    width: 35px;
    height: 35px;
  
    
  }

  