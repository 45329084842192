/* src/components/FacebookButton.css */
.whatsapp-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    width: 3cm; /* Especifica el ancho del enlace en cm */
    background-color: #3b5998;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    
  }
  
  
  .d-fluency-whatsapp-logo{
    width: 35px;
    height: 35px;
    margin-right: 5px; /*Esta propiedad nos permite mover el logo o margen hacia la izquierda*/ 
 
  
    
  }


  .grid-container{
    display: grid;
    grid-template-columns: repeat(2, auto);
    justify-content: center; /* Centra los botones horizontalmente */
    gap: 5px; /* Reduce el espacio entre los botones */
               }
.Micontenedor
{
      display: grid;
  
      justify-content: center; /* Centra los botones horizontalmente */
     


}


  