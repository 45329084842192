/* styles.css */

/* Estilos generales */
body, html {
    margin: 0;
    padding: 0;
    width: 100%;
    overflow-x: hidden; /* Evitar desplazamiento horizontal */
  }
  
  .background-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 20px;
    background: url('../assets/Tecnologia.jpeg') no-repeat center center fixed;
    background-size: cover;
  }
  
  .form-container {
    background: rgba(255, 255, 255, 0.9);
    padding: 20px;
    border-radius: 8px;
    max-width: 100%;
    width: 500px;
    box-sizing: border-box;
  }
  
  .form-heading {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .form-input,
  .form-textarea,
  .form-button {
    width: 100%;
    margin-bottom: 15px;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .form-button {
    background-color: green;
    color: white;
    cursor: pointer;
  }
  
  .form-button:hover {
    background-color: darkgreen;
  }
  
  .success-message,
  .error-message {
    text-align: center;
    margin-top: 20px;
    padding: 10px;
    border-radius: 4px;
  }
  
  .success-message {
    background-color: #d4edda;
    color: #155724;
  }
  
  .error-message {
    background-color: #f8d7da;
    color: #721c24;
  }
  
  /* Media queries para pantallas pequeñas */
  @media (max-width: 600px) {
    .form-container {
      padding: 10px;
      width: 90%; /* Asegurar que el contenedor se ajuste al ancho de la pantalla */
    }
  
    .form-heading {
      font-size: 1.2rem;
    }
  
    .form-input,
    .form-textarea,
    .form-button {
      padding: 8px;
      font-size: 1rem;
    }
  }
  