/* ImagenFondo.css */
/* ImagenFondo.css */
.background-container {
  background-image: url('../assets/Tecnologia.jpeg'); /* Ajusta la ruta según tu proyecto */
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.form-container {
  background: rgba(255, 255, 255, 0.9); /* Fondo blanco semitransparente */
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 600px; /* Ancho máximo del formulario */
  width: 100%;
  text-align: center;
}

.form-heading {
  margin-bottom: 20px;
  font-size: 1.5em;
}

.form-input, .form-textarea, .form-button {
  width: calc(50% - 10px); /* Ancho de los campos del formulario */
  padding: 10px;
  margin: 5px; /* Espaciado entre los campos */
  border-radius: 5px;
  border: 1px solid #ccc;
  display: inline-block;
}

.form-input:last-child, .form-textarea {
  width: 100%; /* Ancho completo para el último campo y el textarea */
}

.form-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
  width: 100%; /* Ancho completo del botón */
  padding: 15px;
}

.form-button:hover {
  background-color: #45a049;
}

.success-message {
  color: rgb(16, 32, 104);
  margin-top: 20px;
}
