/* src/components/FacebookButton.css */
.correo-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  width: 3cm; /* Especifica el ancho del enlace en cm */
  background-color: #3b5998;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
 
    
  }
  
  
  .icons8-gmail-94{
    width: 35px;
    height: 35px;
    margin-right: 10px; /*Esta propiedad nos permite mover el logo o margen hacia la izquierda*/ 
   
 
  
    
  }

