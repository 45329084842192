/* src/components/FacebookButton.css */
.WhatsappButtonGrupo-button{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    width: 7cm; /* Especifica el ancho del enlace en cm */
    background-color: #3b5998;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    
  }
  
  
  .fluency-whatsapp-logo2{
    width: 35px;
    height: 35px;
    margin-right: 10px; /*Esta propiedad nos permite mover el logo o margen hacia la izquierda*/ 
 
  
    
  }
.containergrupo
{
      display: grid;
  
      justify-content: center; /* Centra los botones horizontalmente */
     


}

.background-image {
  background-image: url('../assets/Cursos.jpeg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.btn-primary{

  color:#051d4f
}

  
